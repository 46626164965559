<template>
  <div class="row row-choose-adventure ">
    <div class="standard-image-container-right col-lg-6 col-sm-12">
      <div class="inner-content-image-container">
        <img
            class="no-obstacles-image-right" :class="imageClass"
            :src="srcImage"
            alt="no-obstacles"
        >
      </div>

    </div>

    <ContentTextQuote
        :Title="titleText" :Content="contentText" :name-content="quoteText"
        class="col-lg-6 col-sm-12 order-risk-return"
    />
  </div>
</template>
<script>
import ContentTextQuote from "@/components/product/ContentTextQuote.vue";

export default {
  name: 'LeftImageContent',
  components: {ContentTextQuote},
  props: ["titleText", "contentText", "srcImage", "quoteText", "imageClass"]

}
</script>
<style>


.standard-image-container-right {
  align-items: flex-start;
  display: flex;
  position: relative;
  justify-content: flex-start;
}


.no-obstacles-image-right {
  object-fit: cover;
  width: 100%;
  height: auto;
  float: left;
}


@media (max-width: 992px) {

  /*.no-obstacles-image-right {*/
  /*  object-fit: cover;*/
  /*  width: 100%;*/
  /*  height: auto;*/
  /*}*/

  .values-displayed-ar {
    width: 100% !important;
  }

  .row-choose-adventure {
    margin-top: 50px !important;
  }

}


.row-choose-adventure {
  margin-top: 100px !important;
}


.values-displayed-ar {
  color: var(--black-2);
  font-family: var(--font-family-aktiv_grotesk-thin);
  font-size: 8px;
  font-weight: 275;
  letter-spacing: 0;
  line-height: normal;
  text-align: end;
  position: relative;
  float: left;
  width: 92%;
  margin-top: 12px;

}

.inner-content-image-container {
  height: 332px;
  display: block;
  position: relative;
  flex-display: row;
  width: 499px;
  overflow: hidden;
  border-radius: 3px;
}


@media (max-width: 991.98px) {
  .inner-content-image-container {
    height: auto;
    width: 100%;
  }

  .standard-image-container-right {
    align-items: center;
    display: flex;
  }

  .row-choose-adventure-mobile {
    margin-top: 100px !important;
    margin-left: 0px !important;
  }
}

@media (max-width: 575.98px) {
  .standard-image-container-right {
    align-items: center;
    display: flex;
    order: 1;
  }

}

.border-radius-tl-br-50 {
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

</style>