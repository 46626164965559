const parityflexAPY = 5.1;
const fastbreakAPY = 5.45;
const steadypaceAPY = 5.45;

const newParityflexAPY = 5.95;
const newFastbreakAPY = 6.15;
const newSteadypaceAPY = 6.15;

const ratesFeatureFlag = "rates-change-423";

const formatValue = (value) => value.toFixed(2);

const getParityFlexRate = (showNew, unformatted) => {
  if (typeof showNew !== "boolean") {
    if (unformatted) {
      return 0;
    } else {
      return "X.XX";
    }
  }

  const value = showNew ? newParityflexAPY : parityflexAPY;

  if (unformatted) {
    return value;
  } else {
    return formatValue(value);
  }
};

const getFastBreakRate = (showNew, unformatted) => {
  if (typeof showNew !== "boolean") {
    if (unformatted) {
      return 0;
    } else {
      return "X.XX";
    }
  }

  const value = showNew ? newFastbreakAPY : fastbreakAPY;

  if (unformatted) {
    return value;
  } else {
    return formatValue(value);
  }
};

const getSteadyPaceRate = (showNew, unformatted) => {
  if (typeof showNew !== "boolean") {
    if (unformatted) {
      return 0;
    } else {
      return "X.XX";
    }
  }

  const value = showNew ? newSteadypaceAPY : steadypaceAPY;

  if (unformatted) {
    return value;
  } else {
    return formatValue(value);
  }
};

export {
  getParityFlexRate,
  getFastBreakRate,
  getSteadyPaceRate,
  ratesFeatureFlag,
};
