<template>
  <div class="product-nav-end">
    <div class="product-1-nav-end aktivgrotesk-bold-black-14px">Products</div>

    <div class="about-us-2-nav-end">
      <a :href="steadypaceLink" class="link">
        SteadyPace
      </a>
    </div>
    <div class="about-us-2-nav-end">
      <a :href="fastbreakLink" class="link">
        FastBreak
      </a>
    </div>
    <div class="about-us-2-nav-end">
      <a :href="parityflexLink" class="link">
        ParityFlex
      </a>
    </div>
    <div class="about-us-2-nav-end">
      <a :href="oneupLink" class="link">
        OneUp
      </a>
    </div>
    <div class="about-us-2-nav-end">
      <a :href="protectLink" class="link">
        Gainbridge Protect
      </a>
    </div>

  </div>
</template>

<script>
import Frame1 from "./Frame1.vue";
export default {
  data() {
    return {
      oneupLink: "/oneup.html",
      steadypaceLink: "/steadypace.html",
      fastbreakLink: "/fastbreak.html",
      parityflexLink: "/parityflex.html",
      parityseriesLink: "/parityseries.html",
      protectLink: "/protect.html",
      faqLink: "/faq.html",
      contactusLink: "/contactus.html",
      careersLink: "/careers.html",
      aboutusLink: "/aboutus.html",
      partnershipLink: "/partnership.html",
      termsconditionsLink: "/termsconditions.html",
      privacypolicyLink: "/privacypolicy.html",
      disclosuresLink: "/disclosures.html"
    }
  },
  name: "Product",
  components: {
    Frame1,
  },
  props: ["frame1Props"],
};
</script>

<style>
.product-nav-end {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  padding-bottom: 15px;
}

.product-1-nav-end {
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 0px;
  position: relative;
  /*white-space: nowrap;*/
  /*width: fit-content;*/
}

.about-us-2-nav-end {
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: relative;
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
  /*width: fit-content;*/
}
</style>
